* {
  box-sizing: border-box;
  /* margin: 0;
  padding: 0;*/
  font-family: "Manrope", sans-serif;
}

html {
  scroll-behavior: smooth;
}

a {
  color: var(--main-text);
}

ul {
  margin: 0;
  padding: 0;
}

.home {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow-x: hidden;
  font-size: 2.5rem;
  background-color: var(--background-color);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 800;
  letter-spacing: -0.55px;
  text-transform: uppercase;
  padding: 0;
  margin: 0;
}

p {
  font-size: 1.3rem;
  font-weight: 400;
  padding: 0;
  margin: 0;
}

select option {
  color: var(--main-text);
}

/*---primereact---*/
.p-datatable .p-datatable-thead > tr > th {
  font-weight: 600 !important;
  color: var(--main-text) !important;
  font-family: var(--font-family);
  font-size: 0.85rem;
}

/*---input---*/
.custom-input{
  width: 12rem;
  font-size: 0.85rem;
  border: 2px solid transparent;
  border-radius: 15px;
  box-shadow: 5px 8px 20px 0px rgba(191, 191, 191, 0.25);
  color: var(--main-text);
}

.custom-input .p-inputtext {
  width: 12rem;
  font-size: 0.85rem;
  border: 2px solid transparent;
  border-radius: 15px;
  box-shadow: 5px 8px 20px 0px rgba(191, 191, 191, 0.25);
  color: var(--main-text);
}

.custom-input .p-inputtext:enabled:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.87),
    inset 0 0 0 1px rgba(0, 0, 0, 0.87), inset 0 0 0 1px rgba(0, 0, 0, 0.87),
    inset 0 0 0 1px rgba(0, 0, 0, 0.87);
  border: 2px solid transparent;
}

.custom-input-small .p-inputtext {
  width: 6rem;
  font-size: 0.85rem;
  border: 2px solid transparent;
  border-radius: 15px;
  box-shadow: 5px 8px 20px 0px rgba(191, 191, 191, 0.25);
  color: var(--main-text);
  padding-top: .3rem;
  padding-bottom: .3rem;

}

.custom-input-small .p-inputtext:enabled:focus {
  outline: 0 none;
  outline-offset: 0;
}

@media screen and (max-width: 1280px) {
  .custom-input{
    width: 100%;
  }
  .custom-input .p-inputtext {
    width: 100%;
  }
  .custom-input-small .p-inputtext {
    width: 100%;
  }
}

/*---dropdown---*/
.custom-dropdown .p-dropdown {
  height: 100%;
  width: 12rem;
  align-items: center;
  border: 2px solid transparent;
  border-radius: 15px;
  box-shadow: 5px 8px 20px 0px rgba(191, 191, 191, 0.25);
}
.custom-dropdown .p-dropdown:not(.p-disabled).p-focus {
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.87),
    inset 0 0 0 1px rgba(0, 0, 0, 0.87), inset 0 0 0 1px rgba(0, 0, 0, 0.87),
    inset 0 0 0 1px rgba(0, 0, 0, 0.87);
}

.custom-dropdown .p-dropdown-label {
  font-size: 0.85rem;
  color: var(--main-text);
}

.custom-dropdown .p-dropdown-panel .p-dropdown-items .p-dropdown-item {
  padding: 0.5rem 1rem !important;
}

.custom-dropdown
  .p-dropdown-panel
  .p-dropdown-items
  .p-dropdown-item.p-highlight {
  color: #ffffff;
  background: #878692;
}

.custom-dropdown .p-dropdown-panel {
  box-shadow: 5px 8px 20px 0px rgba(191, 191, 191, 0.75);
}

@media screen and (max-width: 1280px) {
  .custom-input-small .p-inputtext {
    width: 100%;
  }
  .custom-input .p-dropdown {
    width: 100%;
  }
}

/*---btn---*/
.p-button:focus {
  box-shadow: 0 0 0 0.2rem rgba(191, 191, 191, 0.75);
}

.p-button.p-button-outlined:not(:disabled):active {
  background: rgba(191, 191, 191, 0.75);
}

/*---datatable---*/
.p-datatable .p-datatable-tbody > tr.p-highlight-contextmenu {
  outline: 0.15rem solid #67686d;
  outline-offset: -0.15rem;
}

.p-datatable .p-datatable-tbody > tr.p-highlight {
  background: #e6ebf3;
  color: var(--main-text);
}

/*---primereact-end---*/

.home-main-img {
  display: block;
  overflow: hidden;
  width: 100vw;
  padding-top: 70px;
}

.home-main-img img {
  max-height: 100vh;
  width: 100%;
}

.about-us {
  display: flex;
  padding: 8rem 14rem 8rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 55px;
}

.about-us__header {
  display: flex;
  padding: 0px;
  justify-content: center;
  align-items: center;
  align-self: stretch;
}

.about-us__content {
  display: flex;
  align-items: flex-start;
  gap: 50px;
}

.about-us__img {
  display: flex;
  align-items: flex-start;
}

.about-us__img-mobile {
  display: none;
}

.about-us__img-bground {
  display: flex;
  width: 20vw;
  height: 20vw;
  align-items: flex-start;
  border-radius: 2vh;
  background: var(--primary);
}

.about-us__img-bground-mobile {
  display: none;
}

.about-us__image {
  display: flex;
  padding-top: 2vw;
  align-items: flex-start;
  margin-left: -18vw;
}

.about-us__image-mobile {
  display: none;
}

.about-us__image img {
  width: 24vw;
  height: auto;
  border-radius: 2vh;
}

.about-us__image-mobile img {
  border-radius: 2vh;
}

.about-us__text {
  display: flex;
  flex-direction: column;
  text-align: left;
  gap: 1.5rem;
}

.product-cards__wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 5rem 16rem 8rem;
  align-items: center;
  gap: 50px;
}

.company-cards__wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 4rem 14rem 6rem;
  align-items: center;
  gap: 55px;
}

.application-form__wrapper {
  display: flex;
  flex-direction: column;
  background-color: var(--background-color);
  width: 100%;
  padding: 4rem 14rem 6rem;
  align-items: center;
  gap: 55px;
  font-size: 2.5rem;
}

.contact-cards__wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 6rem 2rem 6rem;
  align-items: center;
  gap: 55px;
}

.product {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  overflow-x: hidden;
  padding: 70px 14rem 0;
  font-size: 2.5rem;
  background-color: var(--background-color);
}

.product-description {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 4rem 0;
  gap: 30px;
}

.product-description-header {
  font-weight: 800;
  letter-spacing: -0.55px;
  word-spacing: 5px;
  text-transform: uppercase;
}

.product-description img {
  width: 100%;
}

.product__btn-wrapper {
  display: flex;
  width: 100%;
  justify-content: end;
}

.product-section-wrap {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 4rem 0 6rem;
  gap: 30px;
}

.product__modules {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  margin-bottom: 20px;
}

.product__modules-description {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.product__modules-description-header {
  font-weight: 600;
  margin-bottom: 10px;
}

.product__modules-description-text {
  margin-bottom: 20px;
}

.carousel-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 20px;
  justify-content: center;
  align-self: center;
}

.carousel-slide__content {
  display: flex;
  width: 100%;
  gap: 30px;
}

.carousel-slide__desc {
  display: flex;
  flex-direction: column;
}

.PhoneInput {
  height: 100% !important;
}

.slick-prev:before,
.slick-next:before {
  color: var(--primary) !important;
  opacity: 1 !important;
}

.slick-prev {
  left: -50px !important;
}

.slick-next {
  right: -50px !important;
}

.slick-dots {
  bottom: -45px !important;
}

.slick-prev:before,
.slick-next:before {
  font-size: 30px !important;
}

.slick-prev,
.slick-next {
  width: 30px !important;
  height: 30px !important;
}

.slick-dots li button:before {
  font-size: 12px !important;
}

.slick-dots li.slick-active button:before {
  opacity: 1 !important;
  color: var(--primary) !important;
}

.slick-slide img {
  width: 66% !important;
}

.page-in-development {
  display: flex;
  width: 100%;
  height: 100vh;
  padding: 160px 14vw 6vw;
  font-size: 1.5rem;
  justify-content: center;
  background-color: var(--background-color);
}

@media screen and (max-width: 1600px) {
  .about-us {
    padding: 8rem 10rem 8rem;
  }
  .product-cards__wrapper {
    padding: 5rem 10rem 8rem;
  }
  .company-cards__wrapper {
    padding: 3vw 10rem 6vw;
  }
  .application-form__wrapper {
    padding: 4rem 10rem 6rem;
  }
  .product {
    padding: 70px 10rem 0;
  }
}

@media screen and (max-width: 1367px) {
  .carousel-slide__content {
    flex-direction: column;
    gap: 0;
    align-items: center;
  }
  .product__modules-description-header {
    font-size: 1rem;
  }
  .product__modules-description-text {
    font-size: 1rem;
  }
  .slick-slide img {
    width: 100% !important;
  }
}

@media screen and (max-width: 1300px) {
  .home-main-img {
    height: auto;
  }
  .about-us__content {
    gap: 40px;
  }
  .about-us__img-bground {
    width: 300px;
    height: 300px;
  }
  .about-us__image img {
    width: 30vw;
    height: auto;
  }
  .about-us__image {
    margin-left: -260px;
    padding-top: 40px;
  }
}

@media screen and (max-width: 1080px) {
  .about-us__content {
    flex-direction: column;
    align-items: center;
    gap: 35px;
  }
  .about-us__img {
    display: none;
  }
  .about-us__img-mobile {
    display: flex;
    align-items: flex-start;
    justify-content: center;
  }
  .about-us__img-bground {
    display: none;
  }
  .about-us__image {
    display: none;
  }
  .about-us__image-mobile {
    display: flex;
    padding: 0;
    margin: 0;
  }
  .about-us__image img {
    display: none;
  }
  .about-us__image-mobile img {
    width: 100%;
    height: auto;
    border-radius: 2vh;
  }
}

@media screen and (max-width: 750px) {
  .home {
    font-size: 1.8rem;
  }
  .home-main-img {
    display: none;
  }
  .about-us {
    padding: 7rem 2rem 6rem;
    gap: 30px;
  }
  .application-form__wrapper {
    font-size: 1.8rem;
    padding: 1rem 2rem 6rem;
    gap: 30px;
  }
  .product {
    font-size: 1.8rem;
  }
  .product-cards__wrapper {
    gap: 30px;
    padding: 4rem 2rem 8rem;
  }
  .company-cards__wrapper {
    padding: 5vw 2rem 6vw;
    gap: 30px;
  }
  .contact-cards__wrapper {
    padding: 4rem 2rem 4rem;
    gap: 30px;
  }
  .about-us__content {
    gap: 35px;
  }
  .about-us__img-bground-mobile {
    display: none;
  }
  .about-us__image-mobile {
    margin: 0;
    padding: 0;
  }
  .product {
    padding: 70px 2rem 0;
  }
  .product__btn-wrapper {
    margin-top: 1rem;
  }
  .product__modules {
    padding: 0 2rem;
  }
}

@media screen and (max-width: 500px) {
  .product__btn-wrapper {
    width: 100%;
  }
  .product__btn-wrapper button {
    width: 100%;
  }
  .product-section-wrap {
    padding: 3rem 0 2rem;
  }
}

@media screen and (max-width: 400px) {
  .about-us {
    padding: 7rem 1.5rem 6rem;
  }
  .application-form__wrapper {
    padding: 1rem 1.5rem 6rem;
  }
  .product-cards__wrapper {
    padding: 2rem 1.5rem 7rem;
  }
  .company-cards__wrapper {
    padding: 5vw 1.5rem 6vw;
  }
  .contact-cards__wrapper {
    padding: 4rem 1.5rem 4rem;
  }
  .product {
    padding: 70px 1.5rem 0;
  }
  .product-description {
    padding: 3rem 0;
  }
}

/*-----min-width-----*/
@media screen and (min-width: 1600px) {
  .home-main-img {
    height: auto;
  }
}

@media screen and (min-width: 2400px) {
  .about-us {
    padding: 8rem 20rem 8rem;
  }
  .product-cards__wrapper {
    padding: 5rem 20rem 8rem;
  }
  .application-form__wrapper {
    padding: 4rem 20rem 6rem;
  }
  .product {
    padding: 70px 20rem 0;
  }
}

@media screen and (min-width: 2560px) {
  .home {
    font-size: 3.2rem;
  }
  p {
    font-size: 1.7rem;
  }
  .about-us__content {
    gap: 80px;
  }
}

@media screen and (min-width: 3000px) {
  .about-us {
    padding: 8rem 16vw 8rem;
  }
  .product-cards__wrapper {
    padding: 5rem 16vw 8rem;
  }
  .application-form__wrapper {
    padding: 4rem 16vw 6rem;
  }
  .product {
    padding: 70px 16vw 0;
  }
}
