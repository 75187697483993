.dropdown-menu {
  background: var(--background-color);
  box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.1);
  width: auto;
  min-width: 250px;
  position: absolute;
  top: 70px;
  list-style: none;
  text-align: start;
  padding: 0;
}

.dropdown-menu li {
  background: var(--background-color);
  cursor: pointer;
}

.dropdown-menu li:hover {
  background: #fff;
}

.dropdown-menu.clicked {
  display: none;
}

.dropdown-link {
  display: block;
  height: 100%;
  width: 100%;
  text-decoration: none;
  text-transform: uppercase;
  font-size: 1rem;
  padding: 16px;
}

@media screen and (max-width: 960px) {
  .dropdown-menu {
    background: var(--background-color);
    box-shadow: none;
    min-width: auto;
    position: static;
    text-align: center;
  }
  .dropdown-menu {
    width: 100%;
  }
  .dropdown-menu li:hover,
  .dropdown-menu a:hover {
    background-color: var(--primary);
    color: #fff;
    transition: all 0.3s ease-in-out;
  }
  .fa-caret-down {
    display: none;
  }
}
