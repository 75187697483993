.application-form {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: end;
  padding: 0 14vw;
}

.application-form form {
  padding: 0 2.2vw 2.2vw;
}

.application-form__inner {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  border-radius: 20px;
}

.input-section {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.input__wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 80px;
  gap: 8px;
}

.input__wrapper label {
  font-size: 1.1rem;
  font-weight: 700;
  text-transform: uppercase;
}

.input__wrapper input {
  height: 100%;
  padding: 0 15px;
  border-radius: 15px;
  font-size: 1.1rem;
  border: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  box-shadow: 5px 8px 20px 0px rgba(191, 191, 191, 0.25);
  color: var(--main-text);
}

.phone__wrapper:focus,
.phone__wrapper:active {
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.87),
    inset 0 0 0 1px rgba(0, 0, 0, 0.87), inset 0 0 0 1px rgba(0, 0, 0, 0.87),
    inset 0 0 0 1px rgba(0, 0, 0, 0.87);
}

/*-----dropdown-----*/
.p-dropdown {
  height: 100%;
  align-items: center;
  padding-left: 3px;
  border: 2px solid transparent;
  border-radius: 15px;
  box-shadow: 5px 8px 20px 0px rgba(191, 191, 191, 0.25);
}
.p-dropdown:not(.p-disabled).p-focus {
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.87),
    inset 0 0 0 1px rgba(0, 0, 0, 0.87), inset 0 0 0 1px rgba(0, 0, 0, 0.87),
    inset 0 0 0 1px rgba(0, 0, 0, 0.87);
}

.p-dropdown-label {
  font-size: 1.1rem;
  color: var(--main-text);
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item {
  padding: 0.5rem 1rem !important;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
  color: #ffffff;
  background: #878692;
}

.p-dropdown-panel {
  box-shadow: 5px 8px 20px 0px rgba(191, 191, 191, 0.75);
}
/*-------------------*/

.icon-container i {
  font-size: 1.1rem;
  color: #000;
}

.textarea__wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 25vh;
  gap: 10px;
}

.textarea__wrapper label {
  font-size: 1.1rem;
  font-weight: 700;
  text-transform: uppercase;
}

.textarea__wrapper textarea {
  width: 100%;
  height: 100%;
  padding: 7px 15px;
  border-radius: 15px;
  resize: none;
  font-size: 1.1rem;
  border: 0;
  box-shadow: 5px 8px 20px 0px rgba(191, 191, 191, 0.25);
}

.application-form__btn-wrapper {
  display: flex;
  padding-right: 2.5rem;
}

@media screen and (max-width: 1160px) {
  .application-form {
    gap: 2.5vh;
  }
  .application-form form {
    padding: 3vw;
  }
  .application-form__inner {
    gap: 25px;
  }
  .input-section {
    display: flex;
    flex-direction: column;
    gap: 25px;
  }
  .input__wrapper label {
    font-size: 1rem;
  }
  .input__wrapper input {
    padding: 0 12px;
    font-size: 1rem;
  }
  .input__wrapper select {
    width: 100%;
    padding: 0 12px;
    font-size: 1rem;
  }
  .textarea__wrapper {
    height: 30vh;
    gap: 5px;
  }
  .textarea__wrapper label {
    font-size: 1rem;
  }
  .textarea__wrapper textarea {
    padding: 7px 12px;
    font-size: 1rem;
  }
}

@media screen and (max-width: 1024px) {
  .application-form {
    padding: 0 4rem;
  }
}

@media screen and (max-width: 960px) {
  .application-form {
    padding: 0;
  }
}

@media screen and (max-width: 500px) {
  .application-form__btn-wrapper {
    width: 100%;
    padding: 0 1rem;
  }
  .application-form__btn-wrapper button {
    width: 100%;
  }
}

@media screen and (max-width: 400px) {
  .application-form form {
    padding: 0;
  }
}

@media screen and (min-width: 2560px) {
  .input__wrapper {
    height: 100px;
  }
  .input__wrapper label {
    font-size: 1.4rem;
  }
  .input__wrapper input {
    font-size: 1.4rem;
  }
  .input__wrapper select {
    font-size: 1.4rem;
  }
  .select-box {
    font-size: 1.4rem;
  }
  .icon-container i {
    font-size: 1.4rem;
  }
  .textarea__wrapper label {
    font-size: 1.4rem;
  }
  .textarea__wrapper textarea {
    font-size: 1.4rem;
  }
}
