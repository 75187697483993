body {
  margin: 0;
  font-family: "Manrope", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:root {
  --primary: #212227;
  --background-color: #f2f5f8;
  --main-text: #000;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

::-webkit-scrollbar {
  background-color: transparent !important;
  width: 0.75rem;
  height: auto;
}

::-webkit-scrollbar-thumb {
  background-color: #d9d9d9;
  border-radius: 100px;
}

::-webkit-scrollbar-track {
  background-color: transparent !important;
  margin: 15px 0;
}

@media screen and (max-width: 520px) {
  ::-webkit-scrollbar {
    display: none;
  }

  ::-webkit-scrollbar-thumb {
    display: none;
  }

  ::-webkit-scrollbar-track {
    display: none;
  }
}
