.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  overflow-x: hidden;
  padding: 70px 14rem 0;
  font-size: 2.5rem;
  background-color: var(--background-color);
}

.creation_block {
  display: flex;
  flex-direction: column;
  padding: 3rem 0 12rem;
  height: 100%;
  width: 100%;
  gap: 1rem;
}

.creation_block p {
  font-weight: 700;
  font-size: 0.9rem;
}

@media screen and (max-width: 1600px) {
  .container {
    padding: 70px 10rem 0;
  }
}

@media screen and (max-width: 1400px) {
  .container {
    padding: 70px 9rem 0;
  }
}

@media screen and (max-width: 1280px) {
  .container {
    padding: 70px 14rem 0;
  }
}

@media screen and (max-width: 1152px) {
  .container {
    padding: 70px 8rem 0;
  }
}

@media screen and (max-width: 800px) {
  .container {
    padding: 70px 6rem 0;
  }
}

@media screen and (max-width: 750px) {
  .container h4 {
    font-size: 1.8rem;
  }
}

@media screen and (max-width: 700px) {
  .container {
    padding: 70px 4rem 0;
  }
}

@media screen and (max-width: 510px) {
  .container {
    padding: 70px 1.5rem 0;
  }
}

/*---big-screens---*/
@media screen and (min-width: 2400px) {
  .container {
    padding: 70px 20rem 0;
  }
}

@media screen and (min-width: 3000px) {
  .container {
    padding: 70px 16vw 0;
  }
}

@media screen and (min-height: 1350px) {
  .creation_block {
    height: 67.5vh;
  }
}

@media screen and (min-height: 1600px) {
  .creation_block {
    height: 70vh;
  }
}

@media screen and (min-height: 1750px) {
  .creation_block {
    height: 75vh;
  }
}

@media screen and (min-height: 2100px) {
  .creation_block {
    height: 100vh;
  }
}
