.btn {
  display: inline-block;
  padding: 0.8rem 2rem;
  background-color: var(--primary);
  border-radius: 50px;
  text-align: center;
  font-size: 1rem;
  color: #ffffff;
  border: none;
  outline: none;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.btn:before {
  content: "";
  position: absolute;
  height: 0;
  width: 100%;
  background: #ffffff;
  left: 0;
  bottom: 0;
  transition: all 0.3s;
  opacity: 0.3;
}

.btn:hover:before {
  height: 100%;
}

.btn:before:focus-visible {
  content: "";
  position: absolute;
  height: 0;
  width: 100%;
  background: #fff; /*#ff4c2b*/
  left: 0;
  bottom: 0;
  transition: all 0.3s;
  opacity: 0.3;
}

.btn:focus-visible:before {
  height: 100%;
}

a:focus-visible {
  outline: none;
}

.btn-outlined {
  display: inline-block;
  padding: 0.8rem 2rem;
  background: transparent;
  border-radius: 50px;
  text-align: center;
  font-size: 1rem;
  color: #000000;
  font-weight: 500;
  border: 1.5px solid var(--primary);
  outline: none;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.btn-outlined:before {
  content: "";
  position: absolute;
  height: 0;
  width: 100%;
  background: #bebebe;
  left: 0;
  bottom: 0;
  transition: all 0.3s;
  opacity: 0.3;
}

.btn-outlined:hover:before {
  height: 100%;
}

.btn-outlined:before:focus-visible {
  content: "";
  position: absolute;
  height: 0;
  width: 100%;
  background: #bebebe;
  left: 0;
  bottom: 0;
  transition: all 0.3s;
  opacity: 0.3;
}

.btn-outlined:focus-visible:before {
  height: 100%;
}

@media screen and (min-width: 2560px) {
  .btn {
    font-size: 1.3rem;
  }
}
