.cards {
  padding: 0;
}

.cards__container {
  display: flex;
  flex-flow: column;
  /*align-items: center;*/
  margin: 0 auto;
}

.cards__wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 20px;
  /*position: relative;*/
}

.cards__items {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.cards__item {
  display: flex;
  flex: 1;
  min-height: 546px;
}

.cards__item__link {
  display: flex;
  flex-flow: column;
  width: 100%;
  min-height: 500px;
  box-shadow: 5px 8px 20px 0px rgba(191, 191, 191, 0.25);
  -webkit-filter: drop-shadow(5px 8px 20px 0px rgba(191, 191, 191, 0.25));
  filter: drop-shadow(5px 8px 20px 0px rgba(191, 191, 191, 0.25));
  border-radius: 20px;
  background-color: #fff;
  overflow: hidden;
  text-decoration: none;
  align-items: center;
  padding-bottom: 40px;
}

.cards__item__link:focus {
  outline: auto;
}

.cards__item__pic-wrap {
  position: relative;
  width: 100%;
  padding-top: 54%;
  margin: 0;
  overflow: hidden;
}

.fade-img {
  animation-name: fade-img;
  animation-duration: 2s;
}

.cards__item__img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  object-fit: cover;
  transition: all 0.2s linear;
}

.cards__item__img:hover {
  transform: scale(1.1);
}

.cards__item__description {
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.cards__item__info {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 10px;
  padding: 10px 26px;
  text-align: left;
}

.cards__item__header {
  font-size: 1rem;
  text-transform: uppercase;
  padding-top: 10px;
}

.cards__item__text {
  font-size: 1rem;
}

.companies {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.company-cards {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 20px;
}

.company-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.company-card__logo img {
  display: block;
  width: 13vw;
  height: auto;
  max-height: 4vw;
  border: none;
}

.company-card__name p {
  font-size: 1.5rem;
  text-transform: uppercase;
  font-weight: 800;
}

.contacts {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: flex-start;
}

.contacts-custom {
  display: flex;
  gap: 1.2vw;
}

.contact-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 200px;
  min-width: 200px;
  width: 100%;
  padding: 20px 25px 30px;
  border: none;
  border-radius: 20px;
  background: #fff;
  box-shadow: 5px 8px 20px 0px rgba(191, 191, 191, 0.25);
}

.contact-card__personal-info {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 15px;
}

.contact-card__personal-info img {
  width: 90px;
  height: 90px;
  border-radius: 8px;
  margin-right: 15px;
}

.contact-card__personal-info p {
  font-weight: 700;
  font-size: 0.9rem;
  margin-bottom: 5px;
}

.contact-card__info {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
}

.contact-card__info p {
  font-size: 0.9rem;
}

.contact-card__contacts-job-wrapper {
  display: flex;
  margin-bottom: 10px;
}

.contact-card__contacts-wrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.contact-card__contacts {
  display: flex;
  gap: 10px;
  font-size: 0.9rem;
}

.contact-card__name-hidden {
  display: none;
}

.feature-card {
  list-style: none;
  flex: 1;
}

.feature-cards__wrapper {
  display: flex;
  flex-direction: column;
  gap: 35px;
}

.feature-card__item {
  display: flex;
  gap: 40px;
}

.feature-card__item__mobile {
  display: none;
}

.feature-card__header {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  margin-bottom: 10px;
}

.feature-card__header p {
  font-size: 1rem;
  font-weight: 800;
}

.feature-card__description {
  display: flex;
  height: 100%;
  flex-direction: column;
}

.feature-card__description p {
  font-size: 1rem;
}

@media only screen and (min-width: 1200px) {
  .cards__wrapper {
    gap: 0;
  }
  .cards__items {
    margin: 0;
    padding: 0;
  }
  .cards__items {
    display: flex;
    flex-direction: row;
    margin-bottom: 50px;
  }
  .content__blog__container {
    width: 84%;
  }
}

@media only screen and (max-width: 1500px) {
  .contacts {
    justify-content: center;
  }
  .contacts-custom {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }
  .contact-card {
    flex-direction: row;
    justify-content: start;
    max-width: 500px;
    padding: 20px;
  }
  .contact-card__personal-info {
    flex-direction: column;
    width: fit-content;
    margin: 0;
  }
  .contact-card__personal-info img {
    width: 150px;
    height: 150px;
  }
  .contact-card__personal-info p {
    display: none;
  }
  .contact-card__name-hidden {
    display: flex;
    flex-direction: row;
    gap: 7px;
    margin-bottom: 10px;
  }
  .contact-card__name-hidden p {
    font-size: 1.1rem;
    font-weight: 800;
  }
}

@media screen and (max-width: 1450px) {
  .feature-cards__wrapper {
    gap: 1.5rem;
  }
  .feature-card__item__mobile {
    display: flex;
    gap: 1.5rem;
  }
  .feature-card__item {
    display: none;
  }
}

@media only screen and (max-width: 1200px) {
  .cards__item {
    margin-bottom: 1.5vw;
  }
  .feature-card {
    margin-bottom: 1.5rem;
  }
}

@media screen and (max-width: 1024px) {
  .cards__item__info {
    padding: 1rem 2rem 2.5rem;
  }
}

@media screen and (max-width: 960px) {
  .companies {
    flex-direction: column;
    gap: 20px;
    justify-content: center;
  }
  .company-cards {
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 80px;
  }
  .company-card__logo img {
    width: 160px;
    height: auto;
    min-height: 57px;
  }
  .feature-cards__wrapper {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .feature-card__item__mobile {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .feature-card__item {
    display: none;
  }
}

@media screen and (max-width: 720px) {
  .contacts {
    display: flex;
    flex-direction: column;
    gap: 30px;
    align-items: center;
  }
  .contacts-custom {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }
  .contact-card {
    flex-direction: column;
    justify-content: space-between;
    max-width: 280px;
    padding: 20px;
  }
  .contact-card__personal-info {
    flex-direction: row;
    width: 100%;
    margin-bottom: 10px;
  }
  .contact-card__personal-info img {
    width: 100px;
    height: 100px;
  }
  .contact-card__personal-info p {
    display: flex;
    flex-direction: row;
    gap: 5px;
  }
  .contact-card__name-hidden {
    display: none;
  }
  .contact-card__name-hidden p {
    font-size: 0.9rem;
    font-weight: 800;
  }
  .contact-card__info p {
    font-size: 0.9rem;
  }
}

@media screen and (max-width: 600px) {
  .companies {
    flex-direction: column;
    gap: 20px;
    justify-content: center;
  }
  .company-cards {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
  }
  .company-card__logo img {
    width: 160px;
    height: auto;
    min-height: 57px;
  }
}

@media screen and (max-width: 500px) {
  .cards__item__description button {
    width: 85%;
  }
}

@media screen and (max-width: 400px) {
  .contact-card {
    max-width: 280px;
  }
  .contact-card__personal-info img {
    width: 100px;
    height: 100px;
  }
}

/*---big-screens---*/

@media screen and (min-width: 2560px) {
  .cards__items {
    gap: 40px;
    margin-bottom: 60px;
  }
  .cards__item__info {
    gap: 18px;
    padding: 14px 42px;
  }
  .cards__item__header {
    font-size: 1.3rem;
    padding-top: 14px;
  }
  .cards__item__text {
    font-size: 1.3rem;
  }
  .contact-card {
    padding: 30px 35px 40px;
  }
  .contact-card__info p {
    font-size: 1.15rem;
  }
  .contact-card__contacts {
    font-size: 1.15rem;
  }
  .contact-card__personal-info p {
    font-size: 1.15rem;
  }
  .contact-card__personal-info img {
    width: 120px;
    height: 120px;
    border-radius: 8px;
    margin-right: 25px;
  }
  .contacts-custom {
    gap: 1.6vw;
  }
  .contact-card__contacts-job-wrapper {
    margin-bottom: 16px;
  }
  .contact-card__contacts-wrapper {
    gap: 16px;
  }
}
