.footer {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 3rem 12rem;
  background-color: var(--primary);
  color: var(--background-color);
}

.footer-logo {
  display: flex;
  gap: 20px;
}

.footer-logo img {
  height: 48px;
}

.footer-logo-name {
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.footer-logo-name p {
  font-size: 0.9rem;
  font-weight: 400;
}

.footer-info {
  display: flex;
  flex-direction: column;
  gap: 6px;
  margin-top: 2.5rem;
}

.footer-info p {
  font-size: 0.9rem;
  font-weight: 400;
}

.footer-contry {
  display: flex;
  align-self: center;
}

.footer-contry p {
  font-size: 0.9rem;
}

@media screen and (max-width: 1600px) {
  .footer {
    padding: 3rem 10rem;
  }
}

@media screen and (max-width: 850px) {
  .footer {
    padding: 3rem 5rem;
  }
  .footer-info {
    margin-bottom: 2.5rem;
  }
}

@media screen and (max-width: 400px) {
  .footer {
    padding: 3rem 1rem;
  }
}

@media screen and (min-width: 2400px) {
  .footer {
    padding: 3rem 20rem;
  }
}

@media screen and (min-width: 3000px) {
  .footer {
    padding: 3rem 13vw;
  }
}
