.navbar {
  background: var(--background-color);
  box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.1);
  height: 70px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.1rem;
  font-weight: 600;
  position: fixed;
  top: 0;
  z-index: 1000;
}

.navbar a {
  text-transform: uppercase;
}

.navbar-logo {
  display: flex;
  color: var(--main-text);
  justify-self: start;
  align-items: center;
  margin: 0 20px;
  cursor: pointer;
  text-decoration: none;
  font-size: 1.5rem;
  font-weight: 800;
}

.navbar-logo img {
  height: 42px;
}

.navbar-logo:focus-visible {
  color: #fff;
  text-decoration: none;
  background-color: #a9adb0;
  border-radius: 20px;
  padding: 0.2rem 1rem;
  outline: none;
  transition: all 0.3s ease-in-out;
}

.fa-firstdraft {
  margin-left: 0.5rem;
  font-size: 1.6rem;
}

.nav-menu {
  display: grid;
  grid-template-columns: repeat(5, auto);
  grid-gap: 30px;
  list-style: none;
  text-align: center;
  width: 70vw;
  justify-content: center;
  padding: 0;
  margin: 0;
  margin-right: 2rem;
}

.nav-item {
  display: flex;
  align-items: center;
  height: 80px;
}

.nav-item-desktop {
  display: flex;
  align-items: center;
  height: 80px;
}

.nav-item-mobile {
  display: none;
  align-items: center;
}

.nav-links {
  color: var(--main-text);
  text-decoration: none;
  padding: 0.5rem 1rem;
  border-radius: 20px;
}

.nav-links:hover {
  background-color: var(--primary);
  color: #fff;
  border-radius: 20px;
  transition: all 0.3s ease-in-out;
}

.nav-links:focus-visible {
  background-color: var(--primary);
  color: #fff;
  border-radius: 20px;
  transition: all 0.3s ease-in-out;
  outline: none;
}

.fa-bars {
  color: var(--primary);
}

.nav-links-mobile {
  display: none;
}

.menu-icon {
  display: none;
}

@media screen and (max-width: 1100px) {
  .navbar-logo img {
    display: none;
  }
}

@media screen and (max-width: 960px) {
  .NavbarItems {
    position: relative;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 70px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
    gap: 0;
  }

  .nav-menu.active {
    background: var(--background-color);
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
    justify-content: start;
  }

  .nav-links {
    text-align: center;
    padding: 1rem;
    width: 100%;
    display: table;
  }

  .nav-item {
    height: auto;
  }

  .nav-item-desktop {
    display: none;
  }

  .nav-item-mobile {
    display: flex;
    flex-direction: column;
  }

  .nav-item-mobile a {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    justify-content: center;
    font-size: 1.1rem;
  }

  .nav-links:hover {
    background-color: var(--primary);
    border-radius: 0;
  }

  .navbar-logo {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(25%, 50%);
  }

  .menu-icon {
    display: block;
    position: absolute;
    bottom: 40px;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }

  .fa-times {
    color: var(--primary);
    font-size: 2rem;
  }

  .nav-links-mobile {
    display: block;
    text-align: center;
    padding: 1.5rem;
    margin: 2rem auto;
    border-radius: 20px;
    width: 80%;
    background: var(--primary);
    text-decoration: none;
    color: #fff;
    font-size: 1.5rem;
  }

  .nav-links-mobile:hover {
    background: var(--primary);
    color: #fff;
    transition: 250ms;
  }

  button {
    display: none;
  }
}

/*---big-screens---*/
@media screen and (min-width: 2560px) {
  .navbar {
    height: 90px;
    font-size: 1.5rem;
  }
  .nav-menu {
    grid-gap: 40px;
  }
  .navbar-logo {
    font-size: 2rem;
  }
  .navbar-logo img {
    height: 46px;
  }
  .menu-icon {
    font-size: 2.3rem;
  }
}
